<template>
  <div class="upload">
    <div class="box">
      <!-- 图片回显 -->
      <div v-if="imgs.length">
        <div
          v-for="(v, k) of imgs"
          :key="k"
          class="imgs"
          @mouseenter="
            removeShow = true;
            removeIndex = k;"
          @mouseleave="removeShow = false">
          <img :src="v.img.linkurl" alt="" :style="styleVal" />
          <i @click="removeImg"
            class="el-icon-circle-close"
            v-if="removeShow && k == removeIndex"></i>
        </div>
      </div>
      <!-- 上传模块 -->
      <el-upload
        :headers="headers"
        :style="styleVal"
        :action="uploadUrl"
        list-type="picture-card"
        :show-file-list="false"
        :http-request="Upload"
        name="upload_file"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <!-- 上传中 -->
      <div class="loading" :style="styleVal" v-if="loading">
        <span>上传中</span>
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <!-- 显示可上传张数 -->
    <div class="bottom">
      <p>
        共<span>{{ imgs.length }}</span
        >张，还能上传<span>{{ `${length - imgs.length}` }}</span
        >张
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["height", "width", "length", "token"],
  data() {
    return {
      // 当前选中的图片
      removeIndex: "",
      // 删除按钮显示隐藏
      removeShow: false,
      // 是否正在上传
      loading: false,
      // 文件上传路径
      uploadUrl: "",
      // 图片
      imgs: [],
      //请求头消息
      headers:{}
    };
  },
  methods: {
      async Upload(f) {
        var testmsg = f.file.name.substring(f.file.name.lastIndexOf('.') + 1)
        const extension = testmsg === 'jpg' || testmsg === 'png'
        if (!extension) {
        this.$message.error('图片格式不正确，仅支持jpg或png')
      }
      const Length=this.imgs.length == this.length
      if(Length){ 
        this.$message({
          message: "图片上传个数超出限制",
          type: "error",
        });
      } 
       if(!Length && extension){
        this.loading = true;
        // 创建formData实例
        let formData = new FormData();
        // 将文件和token放入FormData
        // 获取到元素内传入的文件
        formData.append("file", f.file);
        formData.append(
          "Token",
          "bug3e51dw94ppggcgjhslhd1rbw38qih:ocze3k1W1bq6ty35OSZQetf0XQY=:eyJkZWFkbGluZSI6MTY2MjI1OTc4MCwiYWN0aW9uIjoiZ2V0IiwidWlkIjoxMDA2MywiYWlkIjoiMTAwNzUiLCJmcm9tIjoiZmlsZSJ9",
          
        );
        // params.append("Token", this.token);
        const res = await this.$http({
          url: "tietu", //请求路径
          method: "post",
          data: formData,
          // 设置请求头
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(res);
        if (res.status == 200) {
          res.data.name = f.file.name;
          this.handleSuccess(res.data);
          this.removeIndex = res.data.url+res.data.uri
          
        }
      }
    },
    //删除已上传图片
    removeImg() {
      this.imgs.splice(this.removeIndex, 1);
      // 删除之后重置父组件的img数组
     
      this.$emit("getImgs", this.imgs[0].img.linkurl);
    },
    // 上传成功
    handleSuccess(file) {
      //   正在上传loading隐藏
      this.loading = false;
      console.log(file);
      // 上传成功后把请求回来的图片路径放入数组
      this.imgs.push({ img: file, name: file.name });
      console.log(this.imgs[0].img.linkurl)
      // localStorage.setItem("imgs",JSON.stringify(this.imgs))
      // 上传成功之后重置父组件的img数组
      this.$emit("getImgs", this.imgs[0].img.linkurl);
      // const data = {img:this.imgs[0].img}
      // sessionStorage.setItem('img',JSON.stringify(data))
    },
  },
  computed: {
    //
    styleVal() {
      return {
        "--height": this.height,
        "--width": this.width,
      };
    },
  },
};
</script>
<style lang="scss"  scoped>
.box {
  margin-top: 10px;
  display: flex;
  & > div:nth-child(1) {
    display: flex;
    .imgs {
      position: relative;
      width: var(--width);
      height: var(--height);
      margin: 3px;
      img {
        border: 1px solid #eee;
        margin-top: 0;
        width: var(--width);
        height: var(--height);
      }
      i {
        position: absolute;
        right: 5px;
        top: 3px;
        color: #666;
        cursor: pointer;
      }
    }
  }

  & ::v-deep .el-upload {
    margin: 4px;
    height: var(--height);
    width: var(--width);
    line-height: var(--height);
  }
  .loading {
    line-height: var(--height);
    & > span {
      margin-left: 6px;
      color: #888;
      font-size: 12px;
      line-height: var(--height);
    }
    & > i {
      line-height: var(--height);
    }
  }
}
.bottom {
  p {
    color: #999;
    font-size: 12px;
    span {
      color: #e4393c;
      margin: 0 2px;
    }
  }
}
</style>